import { styled } from "~/theme";

export default styled("div", {
  alignItems: "center",
  borderRadius: 4,
  display: "flex",
  height: 24,
  justifyContent: "center",
  fontSize: 13,
  padding: "4px 8px",
  variants: {
    theme: {
      light: {
        backgroundColor: "$grey-10_21",
        color: "$grey-100",
      },
      medium: {
        backgroundColor: "$grey-800",
        color: "$grey-200",
      },
      dark: {
        backgroundColor: "$grey-900",
        color: "$grey-150",
      },
      error: {
        backgroundColor: "$secondary-brick",
        color: "$grey-100",
      },
      purple: {
        backgroundColor: "$primary-purple",
        color: "$grey-150",
      },
      gold: {
        background: "$gradient-gold",
        color: "$grey-900",
      },
      green: {
        backgroundColor: "$secondary-eucalyptus_20",
        color: "$secondary-eucalyptus",
      },
      gradientPurple: {
        background: "$gradient-purple",
        color: "$white",
      },
      gradientYellow: {
        background: "$gradient-yellow",
        color: "$grey-900",
      },
      gradientGreen: {
        background: "$gradient-green",
        color: "$grey-900",
      },
      transparentPurple: {
        backgroundColor: "$primary-purple_30",
        color: "$grey-100",
      },
      transparentGold: {
        background: "$gradient-gold_30",
        color: "$grey-100",
      },
      intense: {
        backgroundColor: "$secondary-sunny",
        color: "$grey-900",
      },
    },
    size: {
      sm: {
        fontSize: 10,
      },
      md: {},
      lg: {
        height: 28,
      },
      xl: {
        height: 32,
        fontSize: 14,
      },
      xxl: {
        height: "$32",
        fontSize: "$24",
        borderRadius: 8,
        padding: "$8",
      },
    },
    weight: {
      normal: {
        fontWeight: "normal",
      },
      medium: {
        fontWeight: "$medium",
      },
      demiBold: {
        fontWeight: "$demi-bold",
      },
    },
    icon: {
      true: {
        gap: 8,
      },
    },
  },
  defaultVariants: {
    theme: "light",
    size: "md",
    weight: "normal",
    icon: false,
  },
});
