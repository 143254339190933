const getGradientCoordinates = (deg: number) => {
  const radians = (deg * Math.PI) / 180;
  const x1 = Math.round(50 + Math.cos(radians) * 50);
  const y1 = Math.round(50 + Math.sin(radians) * 50);
  const x2 = 100 - x1;
  const y2 = 100 - y1;
  return { x1, y1, x2, y2 };
};

const LinearGradient = ({
  id,
  degrees,
  stops,
}: {
  id: string;
  degrees: number;
  stops: { percentage: number; color: string }[];
}) => {
  const gradientCoords = getGradientCoordinates(degrees);

  return (
    <linearGradient
      id={id}
      x1={`${gradientCoords.x1}%`}
      y1={`${gradientCoords.y1}%`}
      x2={`${gradientCoords.x2}%`}
      y2={`${gradientCoords.y2}%`}
    >
      {stops.map(({ percentage, color }, idx) => (
        <stop key={idx} offset={`${percentage}%`} stopColor={color} />
      ))}
    </linearGradient>
  );
};

export const LinearGradientDefs = () => {
  return (
    <>
      <LinearGradient
        id="gradientPurple"
        degrees={135}
        stops={[
          {
            color: "#8758FF",
            percentage: 14.84,
          },
          {
            color: "#6C36FD",
            percentage: 86.43,
          },
        ]}
      />
      <LinearGradient
        id="gradientYellow"
        degrees={90}
        stops={[
          {
            color: "#FFC333",
            percentage: 0,
          },
          {
            color: "#CD7F06",
            percentage: 100,
          },
        ]}
      />
      <LinearGradient
        id="gradientGreen"
        degrees={135}
        stops={[
          {
            color: "#5DD78E",
            percentage: 14.84,
          },
          {
            color: "#11AA4F",
            percentage: 86.43,
          },
        ]}
      />
    </>
  );
};
