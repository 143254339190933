import { ReactNode, Children } from "react";

import { GenericPictureSources } from "~/components/GenericPictureSources/GenericPictureSources";
import { BackButton } from "~/components/Layout";
import { styled } from "~/theme";

interface SubscriptionContentSplitLayoutProps {
  children: ReactNode;
  plan: "pro" | "max" | "scale";
  primaryPanel: "left" | "right";
  onBack: () => void;
}

export function SubscriptionContentSplitLayout({
  children,
  plan = "pro",
  primaryPanel = "left",
  onBack,
}: SubscriptionContentSplitLayoutProps) {
  const count = Children.count(children);
  let left = children;
  let right;

  if (count > 1) {
    const items = Children.toArray(children);
    left = items[0];
    right = items[1];
  }

  return (
    <div style={{ height: "100%", maxWidth: "100vw" }}>
      <BackButton onBack={onBack} />
      <ContentLayout>
        <Panel isPrimaryPanel={primaryPanel === "left"}>{left}</Panel>
        <Panel isPrimaryPanel={primaryPanel === "right"} right={true} plan={plan}>
          {right || (
            <Hero plan={plan}>
              <GenericPictureSources
                name={`paywall-v2/paywall-${plan}-hero`}
                formats={["webp", "png"]}
              />
            </Hero>
          )}
        </Panel>
      </ContentLayout>
    </div>
  );
}

export const ContentLayout = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "$grey-950",
});

export const Panel = styled("div", {
  display: "flex",
  flexGrow: 1,
  maxWidth: "50%",
  padding: "$96",
  justifyContent: "center",
  position: "relative",

  "@bp2": {
    maxWidth: "unset",
  },

  variants: {
    plan: {
      pro: {
        paddingLeft: "$56",
      },
      max: {},
      scale: {},
    },
    right: {
      true: {
        alignItems: "center",
        padding: "unset",
        backgroundColor: "black",
        alignSelf: "stretch",
      },
    },
    isPrimaryPanel: {
      true: {},
      false: {
        "@bp2": {
          display: "none",
        },
      },
    },
  },
});

const Hero = styled("picture", {
  width: "100%",

  "& > img": {
    width: "100%",
  },

  variants: {
    plan: {
      pro: {
        "& > img": {
          paddingLeft: "$56",
        },
      },
      max: {},
      scale: {},
    },
  },
});
