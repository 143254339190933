import { Fragment } from "react";

import {
  DEFAULT_GENERIC_PICTURE_SOURCE_DENSITIES,
  GenericPictureSourceDensity,
  GenericPictureSourceFormat,
  GenericPictureSourcesProps,
} from "./GenericPictureSources.types";

const makeUrl = (
  name: string,
  format: GenericPictureSourceFormat,
  density: GenericPictureSourceDensity
) => {
  return `https://captions-cdn.xyz/desktop-site/${name}-${density}.${format}`;
};

const makeSrcSet = (
  name: string,
  format: GenericPictureSourceFormat,
  densities: GenericPictureSourceDensity[]
) => {
  return densities.map((density) => `${makeUrl(name, format, density)} ${density}`).join(", ");
};

export function GenericPictureSources({
  formats,
  name,
  densities = DEFAULT_GENERIC_PICTURE_SOURCE_DENSITIES,
  fallback,
}: GenericPictureSourcesProps) {
  return (
    <Fragment>
      {formats.map((format) => (
        <Fragment key={format}>
          <source type={`image/${format}`} srcSet={makeSrcSet(name, format, densities)} />
        </Fragment>
      ))}
      <img
        src={makeUrl(name, fallback?.format ?? formats[0], fallback?.density ?? densities[0])}
        alt=""
        draggable={false}
      />
    </Fragment>
  );
}
