import { styled } from "~/theme";

export const Box = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "$16",
  background: "$grey-900",
  border: "1px solid $grey-800",
  borderRadius: "$16",

  variants: {
    layout: {
      default: {
        padding: "$16",
      },
      immersive: {
        minWidth: "auto !important",
      },
    },

    direction: {
      rows: {
        minHeight: "200px",
      },
      columns: {
        minWidth: "500px",
      },
    },
    tendency: {
      grow: {
        flexGrow: 1,
        flexShrink: 0,
      },
      shrink: {
        flexGrow: 0,
        flexShrink: 1,
      },
      auto: {},
    },
  },
});

export const Header = styled("header", {
  display: "flex",
  alignItems: "center",
  gap: "$12",
});

export const Container = styled("div", {
  display: "flex",

  variants: {
    direction: {
      columns: {
        flexDirection: "row",
      },
      rows: {
        flexDirection: "column",
      },
    },
    spacing: {
      default: {
        gap: "$16",
      },
      compact: {
        gap: "$8",
      },
      expanded: {
        gap: "$24",
      },
    },
  },
});
