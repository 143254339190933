import { styled } from "~/theme";

export const SelectorContainer = styled("div", {
  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
      },
      false: {},
    },
  },
});
