import { StudioButton } from "~/modules/studio/StudioButton";
import { styled } from "~/theme";

import ArrowLeft from "../icons/ArrowLeft";

export const BackButton = (
  { onBack, ...rest }: { onBack?: () => void; [x: string]: unknown } = {
    onBack: () => history.back() /** kw: fixme */,
  }
) => {
  return (
    <StyledHeader {...rest}>
      <StudioButton variant="secondary" onClick={onBack} icon={<ArrowLeft />} iconPosition="only" />
    </StyledHeader>
  );
};

const StyledHeader = styled("header", {
  position: "absolute",
  top: "$24",
  left: "$24",
  zIndex: 1,
});
