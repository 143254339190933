import { styled } from "~/theme";

export const DividerContainer = styled("div", {
  backgroundColor: "$grey-800",
  variants: {
    orientation: {
      horizontal: {
        height: "1px",
      },
      vertical: {
        width: "1px",
      },
    },
  },
});
