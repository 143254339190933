export type GenericPictureSourceDensity = "1x" | "1.5x" | "2x" | "3x" | "4x";

export const DEFAULT_GENERIC_PICTURE_SOURCE_DENSITIES: GenericPictureSourceDensity[] = [
  "1x",
  "1.5x",
  "2x",
  "3x",
  "4x",
];

export type GenericPictureSourceFormat = "jpg" | "webp" | "png";

// https://captions-cdn.xyz/desktop-site/${NAME}-${DENSITY}.${FORMAT}
export interface GenericPictureSourcesProps {
  name: string;

  formats: GenericPictureSourceFormat[];

  // will default to all default densities
  densities?: GenericPictureSourceDensity[];

  // if none is specified, will default to the first format and the first density
  fallback?: { format: GenericPictureSourceFormat; density: GenericPictureSourceDensity };
}
