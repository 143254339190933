import React from "react";

import { Tweaks } from "~/components/TweaksWidget/TweaksWidget";
import { styled } from "~/theme";

export function SubscriptionFullPageLayout(props: React.PropsWithChildren) {
  return (
    <LayoutContainer>
      <Tweaks />
      <MainContentContainer>
        <MainContent>{props.children}</MainContent>
      </MainContentContainer>
    </LayoutContainer>
  );
}

export const LayoutContainer = styled("div", {
  display: "flex",
  minHeight: "100vh",
});

export const MainContentContainer = styled("div", {
  flex: 1,
});

export const MainContent = styled("div", {
  backgroundColor: "$grey-950",
  height: "100%",
});
