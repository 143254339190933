import React from "react";

import { SelectorContainer } from "./Selector.styles";
import type { SelectorProps } from "./Selector.types";
import SelectorSelected from "./SelectorSelected";
import SelectorUnselected from "./SelectorUnselected";

export const Selector = ({
  isActive,
  onClick,
  variant = "purple",
  disabled = false,
}: SelectorProps) => {
  return (
    <SelectorContainer disabled={disabled} onClick={onClick}>
      {isActive ? <SelectorSelected variant={variant} /> : <SelectorUnselected />}
    </SelectorContainer>
  );
};
