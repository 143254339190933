import { styled } from "~/theme";

import { LinearGradientDefs } from "./LinearGradient";

function Checkmark({
  variant,
  ...props
}: {
  variant?: "gradientPurple" | "gradientYellow" | "gradientGreen";
}) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <LinearGradientDefs />
      </defs>
      <path
        d="M7 11.5L10.5 15L17 8.5"
        stroke={variant ? `url(#${variant})` : "currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(Checkmark, {
  color: "$primary-violet",
  width: 24,
  height: 24,
});
