import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useAuthState } from "~/context/CaptionsAuthContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { PriceDetails } from "~/modules/subscription/SubscriptionStore";
import { styled } from "~/theme";
import { Source, SubscriptionEvents } from "~/utils/analytics/SubscriptionEvents";

import { useToast } from "../Toast";

const KEY_MAP = {
  test: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_TEST_KEY,
  live: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
};

const GOOGLE_CONVERSION_MAP = {
  pro: "AW-428533084/01TuCPafy9QZENzKq8wB",
  max: "AW-428533084/OjmuCPmfy9QZENzKq8wB",
  scale: "AW-428533084/WQSlCOmqy9QZENzKq8wB",
};

export const stripePromise = (stripeKeyMode: CheckoutFormProps["stripeKeyMode"]) =>
  loadStripe(`${KEY_MAP[stripeKeyMode || "live"]}`);

export interface CheckoutFormProps {
  onComplete: () => void;
  fullWidth?: boolean;
  embeddedCheckoutClientSecret: string;
  stripeKeyMode?: "test" | "live";
  referrer: Source | null;
  autoHandleSubscriptionActivated?: {
    priceDetails: PriceDetails;
  };
}

/**
 * Embeds Stripe's checkout form in the page.
 *
 * @param embeddedCheckoutClientSecret - The client secret used to initialize Stripe.js embedded form. Although it's called "secret", this is safe to expose in frontend.
 * @link https://stripe.com/docs/api/checkout/sessions/object#checkout_session_object-client_secret
 * @reference https://stripe.com/docs/checkout/embedded/quickstart?client=next
 */
export default function CheckoutForm({
  embeddedCheckoutClientSecret,
  referrer,
  stripeKeyMode = "live",
  autoHandleSubscriptionActivated,
  onComplete,
  fullWidth = false,
}: CheckoutFormProps) {
  const { user, refreshUserProfile } = useAuthState();
  const trackSubscribe = SubscriptionEvents.subscribe.useTrackEventCallback();
  const trackSubscriptionStep = SubscriptionEvents.subscription_step.useTrackEventCallback();
  const analytics = useAnalytics();
  const toast = useToast();

  /**
   * Keep polling the user profile until the subscription is activated.
   *
   * The subscription isn't activated until Stripe successfully communicates to RevenueCat that we've received payment.
   * In practice, this should only take a few seconds, but we'll poll for up to 5 minutes to be safe.
   */
  const getIsActiveSubscription = async (expirationTimeMillis: number): Promise<boolean> => {
    const didTimeout = Date.now() > expirationTimeMillis;

    try {
      const userProfile = await refreshUserProfile();

      const didFindActiveSubscription =
        typeof userProfile?.subscription?.managePaymentUrl === "string";

      if (didFindActiveSubscription) {
        return true;
      } else if (didTimeout) {
        return false;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await getIsActiveSubscription(expirationTimeMillis);
      }
    } catch (error) {
      if (!didTimeout) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await getIsActiveSubscription(expirationTimeMillis);
      } else {
        return false;
      }
    }
  };

  const trackStripeCheckoutSuccess = (priceDetails: PriceDetails) => {
    const eventProperties = {
      region: user?.geo?.region || "",
      currency: priceDetails.currencyCode,
      display_currency: priceDetails.currencyCode,
      display_price: priceDetails.price,
      price_in_usd: priceDetails.price,
      source: referrer,
    };

    if (window?.gtag) {
      window.gtag("event", "conversion", {
        send_to: GOOGLE_CONVERSION_MAP[priceDetails.plan],
        value: priceDetails.price,
        currency: priceDetails.currencyCode,
      });

      window.gtag("event", "conversion", {
        send_to: "AW-428533084/NfZ9CO-up44ZENzKq8wB",
        value: priceDetails.price,
        currency: priceDetails.currencyCode,
      });
    }

    analytics.track(
      `checkout_completed_${priceDetails.plan}_${priceDetails.frequency}`,
      eventProperties
    );

    trackSubscriptionStep({
      step: "waiting-till-subscription-activated",
      entitlement: priceDetails.plan,
      plan_type: priceDetails.frequency,
      ...eventProperties,
    });
  };

  const handleSubscriptionActivatedAndTrackEvents = async () => {
    if (!autoHandleSubscriptionActivated?.priceDetails) {
      return;
    }

    trackStripeCheckoutSuccess(autoHandleSubscriptionActivated.priceDetails);

    const expirationTime = Date.now() + 1000 * 60 * 5;
    getIsActiveSubscription(expirationTime)
      .then(() => {
        // subscription is considered active
        trackSubscribe({
          region: user?.geo?.region || "",
          entitlement: autoHandleSubscriptionActivated.priceDetails.plan,
          display_currency: autoHandleSubscriptionActivated.priceDetails.currencyCode,
          display_price: autoHandleSubscriptionActivated.priceDetails.price,
          plan_type: autoHandleSubscriptionActivated.priceDetails.frequency,
          price_in_usd: autoHandleSubscriptionActivated.priceDetails.price,
          source: referrer,
        });

        toast.add("Subscription active.", {
          severity: "success",
        });
      })
      .catch(() => {
        toast.add("Unable to get activated subscription. Retry later, or contact support", {
          severity: "error",
        });
      });
  };

  const _onComplete = async () => {
    if (autoHandleSubscriptionActivated) {
      await handleSubscriptionActivatedAndTrackEvents();
    }
    onComplete();
  };

  return (
    <CheckoutFormContainer fullWidth={fullWidth}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise(stripeKeyMode)}
        options={{
          clientSecret: embeddedCheckoutClientSecret,
          onComplete: _onComplete,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </CheckoutFormContainer>
  );
}

export const CheckoutFormContainer = styled("div", {
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
      false: {
        width: "90vw",
      },
    },
  },
});
