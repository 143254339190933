import { createStore } from "zustand";
import { combine } from "zustand/middleware";

import { Source } from "~/utils/analytics/SubscriptionEvents";
import { createStoreContextWithCreator } from "~/utils/zustand-store";

export type SubscriptionStoreType = ReturnType<typeof createSubscriptionStore>;

export type PaymentFrequency = "monthly" | "yearly";
export type Plan = "pro" | "max" | "scale";
export type GradientTheme = "gradientPurple" | "gradientYellow" | "gradientGreen";
export type State = "plan" | "checkout" | "loading";
export type StripeKeyMode = "live" | "test";

export type PriceDetails = {
  currencyCode: string;
  stripePriceId: string;
  frequency: PaymentFrequency;
  plan: Plan;
  price: number;
};

const updateQueryParam = ({
  plan,
  paymentFrequency,
}: {
  plan?: Plan;
  paymentFrequency?: PaymentFrequency;
}) => {
  const url = new URL(window.location.href);
  if (plan) {
    url.searchParams.set("tier", plan);
  }

  if (paymentFrequency) {
    url.searchParams.set("frequency", paymentFrequency);
  }
  window.history.replaceState(null, "", url);
};

export const getReferrer = () => {
  const url = new URL(window.location.href);
  const referrer = url.searchParams.get("source") || "";

  const validReferrers = [
    "ai-edit",
    "ai-shorts",
    "profile",
    "api",
    "ai-avatar",
    "ai-ads",
    "ai-creator",
    "social-studio",
    "ads-studio",
  ] as Source[];

  if (validReferrers.includes(referrer as Source)) {
    return referrer as Source;
  }

  return null;
};

const onUpdatePlanOrFrequency = ({
  plan,
  paymentFrequency,
  currentState,
  updateState,
}: {
  plan?: Plan;
  paymentFrequency?: PaymentFrequency;
  currentState: State;
  updateState: ({ state }: { state: State }) => void;
}) => {
  updateQueryParam({ plan, paymentFrequency });
  if (currentState === "checkout") {
    updateState({ state: "plan" });
  }
};

const createSubscriptionStore = (
  data?: {
    plan?: Plan;
    paymentFrequency?: PaymentFrequency;
    checkoutSecret?: string;
    state?: State;
    stripeKeyMode?: StripeKeyMode;
  } | null
) => {
  return createStore(
    combine(
      {
        plan: data?.plan ?? "pro",
        paymentFrequency: data?.paymentFrequency ?? "monthly",
        checkoutSecret: data?.checkoutSecret ?? "",
        state: data?.state ?? "plan",
        stripeKeyMode: data?.stripeKeyMode ?? "live",
      },
      (set, get) => ({
        updatePlan: (plan: Plan) => {
          set({ plan });
          onUpdatePlanOrFrequency({
            plan,
            currentState: get().state,
            updateState: set,
          });
        },
        updatePaymentFrequency: (paymentFrequency: PaymentFrequency) => {
          set({ paymentFrequency });
          onUpdatePlanOrFrequency({
            paymentFrequency,
            currentState: get().state,
            updateState: set,
          });
        },
        startCheckout: (checkoutSecret: string, stripeKeyMode?: StripeKeyMode) => {
          set({ checkoutSecret, state: "checkout", stripeKeyMode });
        },
        updateState: (state: State) => set({ state }),
      })
    )
  );
};

export const priceDetailsSelector = (s: { plan: Plan; paymentFrequency: PaymentFrequency }) =>
  PLAN_TO_DETAILS[s.plan][s.paymentFrequency];

export const priceWeeklySelector = (s: { plan: Plan; paymentFrequency: PaymentFrequency }) => {
  let weeks;
  switch (s.paymentFrequency) {
    case "monthly":
      weeks = 4;
      break;
    case "yearly":
    default:
      weeks = 52;
      break;
  }
  const price = priceDetailsSelector(s).price;
  return Math.floor((price / weeks) * 100) / 100;
};

export const gradientThemeSelector = (s: { plan: Plan }): GradientTheme => {
  switch (s.plan) {
    case "scale":
      return "gradientGreen";
    case "max":
      return "gradientYellow";
    default:
      return "gradientPurple";
  }
};

export const [useCreateSubscriptionStore, SubscriptionStoreProvider, useSubscriptionStore] =
  createStoreContextWithCreator(createSubscriptionStore);

// KW: should be served from BE
const PRICES: PriceDetails[] = [
  {
    frequency: "monthly",
    price: 9.99,
    stripePriceId: "price_1PDtffIzvyWq37N43YOaSLqg",
    currencyCode: "USD",
    plan: "pro",
  },
  {
    frequency: "yearly",
    price: 89.99,
    stripePriceId: "price_1PDu0UIzvyWq37N4H5v2XnVH",
    currencyCode: "USD",
    plan: "pro",
  },
  {
    frequency: "monthly",
    price: 24.99,
    stripePriceId: "price_1PDti6IzvyWq37N4jR8NM9m9",
    currencyCode: "USD",
    plan: "max",
  },
  {
    frequency: "yearly",
    price: 199.99,
    stripePriceId: "price_1PDthHIzvyWq37N4ygKhMQWv",
    currencyCode: "USD",
    plan: "max",
  },
  {
    frequency: "monthly",
    price: 69.99,
    stripePriceId: "price_1PyyrqIzvyWq37N43NNWyghD",
    currencyCode: "USD",
    plan: "scale",
  },
  {
    frequency: "yearly",
    price: 629.99,
    stripePriceId: "price_1PyytMIzvyWq37N4izvNx20v",
    currencyCode: "USD",
    plan: "scale",
  },
];

const PLAN_TO_DETAILS: Record<Plan, Record<PaymentFrequency, PriceDetails>> = PRICES.reduce(
  (acc: Record<Plan, Record<PaymentFrequency, PriceDetails>>, curr: PriceDetails) => {
    if (!acc[curr.plan]) {
      acc[curr.plan] = {} as Record<PaymentFrequency, PriceDetails>;
    }
    acc[curr.plan][curr.frequency] = curr;
    return acc;
  },
  {} as Record<Plan, Record<PaymentFrequency, PriceDetails>>
);
