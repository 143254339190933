import React from "react";

import { LinearGradientDefs } from "../icons/LinearGradient";

import type { SelectorSelectedProps } from "./Selector.types";

const SelectorSelected = ({ variant }: SelectorSelectedProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle
        cx="17"
        cy="17"
        r="12"
        fill="#181818"
        stroke={getStroke(variant)}
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="gold_gradient"
          x1="-1.84812"
          y1="1.38669"
          x2="30.5029"
          y2="1.38669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CA7900" />
          <stop offset="0.0963" stopColor="#CD7F06" />
          <stop offset="0.2267" stopColor="#D79016" />
          <stop offset="0.3767" stopColor="#E6AD31" />
          <stop offset="0.5402" stopColor="#FBD456" />
          <stop offset="0.5697" stopColor="#FFDC5E" />
          <stop offset="1" stopColor="#CA7900" />
        </linearGradient>
        <LinearGradientDefs />
      </defs>
    </svg>
  );
};

const getStroke = (variant: SelectorSelectedProps["variant"]) => {
  switch (variant) {
    case "purple":
      return "#8C20F8";
    case "gradientPurple":
    case "gradientYellow":
    case "gradientGreen":
      return `url(#${variant})`;
    default:
      return "url(#gold_gradient)";
  }
};

export default SelectorSelected;
