import React from "react";

const SelectorUnselected = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <g filter="url(#filter0_d_240_21032)">
        <g filter="url(#filter1_b_240_21032)">
          <circle cx="17" cy="17" r="16.3333" stroke="#41434A" strokeWidth="1.33333" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_240_21032"
          x="-9.53674e-07"
          y="-9.53674e-07"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_240_21032" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_240_21032"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_240_21032"
          x="-20.4444"
          y="-20.4444"
          width="90.8889"
          height="90.8889"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.2222" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_240_21032" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_240_21032"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SelectorUnselected;
